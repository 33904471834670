import React, { useEffect, useRef, useState } from "react";
import "../scss/BlogPage6.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import FK from "./FK.png"; 
import email from "./email_black.png";
import leenanair from "./leena_nair.png";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import yemihead from "./yemi_head.png";
import circularblog from "./circular-blog.png";

export default function BlogPage4() {
  const data = useStaticQuery(graphql`
  query MyQueryCEBlog {
    allContentfulHeroImage (filter:{id:{eq:"a2a577ad-8ddf-5fe3-9ddf-3d241f98d62c"}}){
      edges {
        node {
          blogCategory
          blogTitle
          date
          readTime
          author {
            firstName
          }
          id
          socialMediaIcons {
            linkedInIcon {
              image {
                file {
                  url
                }
              }
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
            }
          }
          heroImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
}
  `);


  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const breakpoint = 2000; // Adjust this value to determine when the icons should disappear

      if (scrollPosition > breakpoint) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };
  

  const [isSocialMediaBlogVisible, setIsSocialMediaBlogVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 6500; // Adjust this value to determine when to hide socialMediaBlog

      if (scrollPosition > scrollThreshold) {
        setIsSocialMediaBlogVisible(false);
      } else {
        setIsSocialMediaBlogVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  return (
    <div className={`DTMCE`}>
    <div className="contentFeatureBlogDTMCE">
      <Helmet>
        <meta property="og:title" content="The Rise of Luxury Resale"/>
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/2Ah6fKRvmSY7IZt0xLwplV/8b1cfb60d1bf119c25da45997a7f825b/pnggg_1.png" />
        <meta property="og:description" content="There are new ways and business models to help luxury companies grow. The luxury circular economy is a business model that is being used by savvy luxury brands to grow revenue and profitability." />
        <meta property="og:url" content="https://thefreshkid.com/what-is-luxury-circulareconomy/" />
      </Helmet>

      <div className="SocialMediaBlog3MCE" data-aos="fade-up">
      {isSocialMediaBlogVisible && (
          <>
            <div
              className="Share3MCE"
            >
              Share
            </div>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/luxuryfashion/">
              <img
                className="SocialIconBlog3MCE"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
              />
            </a>

            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/luxuryfashion/" target="_blank">
            <img
                className="SocialIconBlog3MCE"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
              />
            </a>

            <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/luxuryfashion/" target="_blank">
              <img
                className="SocialIconBlog13MCE"
                alt=""
                key=""
                src={email}
                style={{ marginRight: '100px' }} 
              />
            </a>
          </>
        )}
      </div>

      <div className="image-and-text-containerMCE">
        <div className="image-containerMCE">
            <img
            className={"imgLDT small-imageMCE"}
            src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}
            alt=""
            />
        </div>
        <div className="text-containerMCE">
        </div>
      </div>

      <div className="image-and-text-containerMediaQueryCE">
        <div className="text-containerMediaQueryCE">
            <p className={"categoryBlogDTMCE"}>
            {data.allContentfulHeroImage.edges[0].node.blogCategory}
            </p>
            <p className={"titleBlogDTMCE"}>
            {data.allContentfulHeroImage.edges[0].node.blogTitle}
            </p>
            <p className={"lineDT2MCE"}>______________</p>

            
            <div className="digitalColumnMCE">
              <div className={"digitalImageMCE"}>
                <img
                  className={"bigImage"}
                  alt={""}
                  key={""}
                  src={
                    yemihead
                  }
                />
              </div>
              <div className={"digitalTextMCE"}>
                <p className={"authorBlogDTMCE"}>
                {data.allContentfulHeroImage.edges[0].node.author.firstName}
                </p>
                <p className={"dateBlogDTMCE"}>
                {data.allContentfulHeroImage.edges[0].node.date}
                </p>
                <p className={"readTimeBlogDTMCE"}>
                {data.allContentfulHeroImage.edges[0].node.readTime}
                </p>
                </div>
            </div>
        </div>
        <div className="image-containerMediaQueryCE">
            <img
            className={"imgLDTMediaQuery small-imageMediaQueryCE"}
            src={circularblog}
            alt="luxury circular economy"
            />
        </div>
      </div>

        

        {/* {isContentsVisible && (
        <div>
          <div className="contentsListb3">
            <p className="contentb3">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}

      <div id="1" className="nav1">1</div>
      <div id="2" className="nav2">2</div>
      <div id="3" className="nav3">3</div>
      <div id="4" className="nav4">4</div>
      <div id="5" className="nav5">5</div> */}




      {/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_sticky_social_bar */}


      </div>
      {/* <div style={{ marginTop: "300vw" }}>
        <NewsletterBar />
      </div>
      
      <Footer /> */}
    </div>
  );
}



