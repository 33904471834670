import React, { useState, useEffect } from "react";
import "../scss/BlogPage6All.scss";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import bof from './bof.png';
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';
import book1 from './book1111.png';
import book2 from './book2222.png';
import book3 from './book3333.png';
import book4 from './book4444.png';
import book5 from './book5555.png';
import leftArrow from './left.png';
import rightArrow from './right.png';
import { DiscussionEmbed } from "disqus-react";


const BlogPage6All = () => {

    const data = useStaticQuery(graphql`
    query MyQueryCircularEconomy {
        text1: allContentfulTextBlock(filter: { id: { eq: "213f1449-6540-54ab-9ab4-3f8ab9f5f428" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text2: allContentfulTextBlock(filter: { id: { eq: "560cdced-f9b6-5185-8cea-522a03a7fa96" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text3: allContentfulTextBlock(filter: { id: { eq: "101cc509-24a9-5a81-b8e7-a90ab45ba46c" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text4: allContentfulTextBlock(filter: { id: { eq: "cf52c2a5-e402-5a52-817b-11d5d64d34ea" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        pic1: allContentfulImageBlock(filter: { name: { eq: "Blog 6 Image 1" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic2: allContentfulImageBlock(filter: { name: { eq: "Blog 6 Image 2" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic3: allContentfulImageBlock(filter: { name: { eq: "Blog 6 Image 3" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic4: allContentfulImageBlock(filter: { name: { eq: "Blog 6 Image 4" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic6: allContentfulImageBlock(filter: { name: { eq: "latin" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }



    }
  `);


  const t1 = data.text1.edges[0].node;
  const t2 = data.text2.edges[0].node;
  const t3 = data.text3.edges[0].node;
  const t4 = data.text4.edges[0].node;
  const p1 = data.pic1.edges[0].node;
  const p2 = data.pic2.edges[0].node;
  const p3 = data.pic3.edges[0].node;
  const p4 = data.pic4.edges[0].node;
  const p6 = data.pic6.edges[0].node;



  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };



    const [activeIndexes, setActiveIndexes] = useState([]);
  
    const handleClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((i) => i !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };





    // const [activeSection, setActiveSection] = useState("");

    // const sectionIds = [
    //   "It's all in your mind",
    //   "Nurture and Protect",
    //   "Exercise",
    //   "You are what you read",
    //   "Sleep and rest Well",
    // ];
  
    // useEffect(() => {
    //   const handleScroll = () => {
    //     const sectionOffsets = sectionIds.map((sectionId) => {
    //       const sectionElement = document.getElementById(sectionId);
    //       return {
    //         id: sectionId,
    //         offsetTop: sectionElement.offsetTop,
    //         offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
    //       };
    //     });
    
    //     const currentScrollPosition = window.scrollY + window.innerHeight / 2;
    
    //     for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
    //       if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
    //         setActiveSection(id);
    //         break;
    //       }
    //     }
    //   };
    
    //   window.addEventListener("scroll", handleScroll);
    
    //   return () => {
    //     window.removeEventListener("scroll", handleScroll);
    //   };
    // }, []);







    const [isContentsVisible, setIsContentsVisible] = useState(false);

    useEffect(() => {
      Aos.init({ duration: 2000 });
    
      const showThreshold = 500; // Adjust this value to determine when to show the contents
      const hideThreshold = 10000; // Adjust this value to determine when to hide the contents
      let isScrollingDown = false;
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
    
        if (scrollPosition > showThreshold && !isScrollingDown) {
          setIsContentsVisible(true);
          isScrollingDown = true;
        } else if (scrollPosition <= showThreshold && isScrollingDown) {
          setIsContentsVisible(false);
          isScrollingDown = false;
        }
    
        // Add logic to hide content near the bottom
        if (scrollPosition > hideThreshold) {
          setIsContentsVisible(false);
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleArrowClick = () => {
      // Scroll down 300 pixels when the arrow is clicked
      window.scrollBy({
        top: 400,
        behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
      });
    };

    const images = [
      book1, book2, book3, book4, book5,
    ];

    const texts = [
      ['Radical Candour -  Silicon Valley Technology Leadership Handbook', 'If you are responsible for the performance of a team especially technology teams this book is invaluable.'],
      ['Rich Dad Poor Dad -  Investment Mindset Advice', 'A good place to start for those starting on their investment journey.'],
      ['The Art of Being and Becoming - Personal Development', 'A belief system of how to conduct yourself at work and in life.'],
      ['The Aficionados -  Luxury Travel and Design', 'The world’s most tasteful hotels, designers and tastemakers curated'],
      ['Monocle  -  Design, Travel and Taste', 'For all things related to good taste'],
    ];
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };
  
    const prevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const disqusConfig = {
      shortname: "tfk-1",
      config: {
      },
      language: "en",
    };

 



  return (
    <>
      <Helmet>
        <title>The Rise of Luxury Resale</title>
        <meta name="description" content="Gain New Customers with Luxury's New Business Model - A Complete Guide to the Luxury Circular Economy" />
        <meta name="keywords" content="luxury circular economy, luxury circular economy examples" />
        <h1>The Rise of Luxury Resale</h1>
      </Helmet>
    <div className={`all1`}>
    <div className={`check1`}>



    {/* {isContentsVisible && (
        <div>
          <div className="contentsListads">
            <p className="contentads">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                // href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )} */}





      <Helmet>
        <h2>Luxury Brands can grow Differently</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="CEBlogContainer">
          <div className="textContainerCEBlog">
            {t1.bodyText && (
              <div className="titleCEBlogNo1">
                Luxury Brands can grow Differently
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerCEBlogPicture1">
          <div className={"imageContainerCEBlogPicture1"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="what is luxury circular economy"
              key={""}
              src={p1.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="CEBlogContainerText">
          <div className="bodyTextContainerCEBlog">
            {t1.bodyText && (
              <div className="bodyCEBlogNo1">
                {documentToReactComponents(JSON.parse(t1.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogCE1'}>
              <img
                className={'arrowCE'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>







      <Helmet>
        <h2>Selfridges Luxury Department Store - Luxury Circular Economy Success Story</h2>
        <h3>Diversified Revenue Stream</h3>
        <h3>Enhanced Brand Image</h3>
        <h3>New Customers and Enhanced Brand Loyalty</h3>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="CEBlogContainer2">
          <div className="textContainerCEBlog2">
            {t2.bodyText && (
              <div className="titleCEBlogNo2">
                Selfridges Luxury Department Store - Luxury Circular Economy Success Story
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerCEBlogPicture2">
          <div className={"imageContainerCEBlogPicture2"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury circular economy examples"
              key={""}
              src={p2.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="CEBlogContainerText2">
          <div className="bodyTextContainerCEBlog2">
            {t2.bodyText && (
              <div className="bodyCEBlogNo2">
                {documentToReactComponents(JSON.parse(t2.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogCE2'}>
              <img
                className={'arrowCE'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>








      <Helmet>
        <h2>Luxury Resale Market Growing Fast</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="CEBlogContainer3">
          <div className="textContainerCEBlog3">
            {t3.bodyText && (
              <div className="titleCEBlogNo3">
                Luxury Resale Market Growing Fast
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerCEBlogPicture3">
          <div className={"imageContainerCEBlogPicture3"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury business model"
              key={""}
              src={p3.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="CEBlogContainerText3">
          <div className="bodyTextContainerCEBlog3">
            {t3.bodyText && (
              <div className="bodyCEBlogNo3">
                {documentToReactComponents(JSON.parse(t3.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogCE3'}>
              <img
                className={'arrowCE'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>










      <Helmet>
        <h2>Luxury Resale Boosts New Luxury</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="CEBlogContainer4">
          <div className="textContainerCEBlog4">
            {t4.bodyText && (
              <div className="titleCEBlogNo4">
                Luxury Resale Boosts New Luxury
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
      <div className="containerCEBlogPicture4">
        <div className={"imageContainerCEBlogPicture4"}>
          <video
            autoPlay
            muted
            loop
            data-aos="fade-up"
            className={"bigImage"}
            key={""}
            src={p4.image.image.file.url}
            alt="hermes bag luxury resale"
          />
        </div>
      </div>
    </div>



      <div>
        <div className="CEBlogContainerText4">
          <div className="bodyTextContainerCEBlog4">
            {t4.bodyText && (
              <div className="bodyCEBlogNo4">
                {documentToReactComponents(JSON.parse(t4.bodyText.raw))}
              </div>
            )}
          </div>
          {/* <div className={'arrowContainerBlogCE4'}>
              <img
                className={'arrowCE'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div> */}
        </div>
      </div>







      <div>
        <div className="containerCEBlogLatin">
          <div className={"imageContainerCEBlogLatin"}>
            <img
              className={"bigImage"}
              key={""}
              src={p6.image.image.file.url}
            />
          </div>
        </div>
      </div>












      <div>
      <div className="comment-box-titleCE">Join the Conversation</div>
      <div className="comment-box-containerCE">
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </div>


      
    </div>
    {/* <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div> */}
{/*       
      <Footer /> */}
    </div>
    </>
  );
}

export default BlogPage6All;



