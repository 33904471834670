import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogPage6 from "../components/BlogPage/BlogPage6"
import BlogPage6All from "../components/BlogPage/BlogPage6All"
import BlogContents from '../components/BlogPage/BlogContents'
import { Helmet } from "react-helmet";

function blog({ data }) {
  return (
    
    <LayoutBlog>
      <Helmet>
        <meta property="og:title" content="The Rise of Luxury Resale"/>
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/2Ah6fKRvmSY7IZt0xLwplV/8b1cfb60d1bf119c25da45997a7f825b/pnggg_1.png" />
        <meta property="og:description" content="There are new ways and business models to help luxury companies grow. The luxury circular economy is a business model that is being used by savvy luxury brands to grow revenue and profitability." />
        <meta property="og:url" content="https://thefreshkid.com/what-is-luxury-circulareconomy/" />
      </Helmet>
      <BlogPage6/>
      <BlogPage6All/>
    </LayoutBlog>
  )
}


export default blog





